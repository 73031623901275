import React from 'react'
import './terms.css'
import { Typography } from '@mui/material'

const Terms = () => {
  return (
    <div className='bg'>
      <br/>
      <br/>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      <center>
       <Typography variant='h2'>TERMS AND POLICIES</Typography>
      
       </center>
      
    </div>
  )
}

export default Terms
