import React from 'react';
import "./home.css";
import Nav from './Nav';


const Home = () => {
  return (
    <div className='bg-container'>
        <Nav />
    </div>
  )
}

export default Home